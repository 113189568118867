import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Schickes Design oder Barrierefreiheit? Mit der CSS-Pseudoklasse `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` könnt
ihr sowohl Tastaturnutzer:innen unterstützen als auch ästhetisch anspruchsvolle Kund:innen zufriedenstellen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1xWXKuE8U//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMRBP/aAAgBAQABBQJ2yterJXcHU2sZs5xqf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABERIQQXH/2gAIAQEABj8Cl6IqTao08Pp//8QAGxAAAgMAAwAAAAAAAAAAAAAAABEBITFBYYH/2gAIAQEAAT8hqzYrt6M9zSKVKYOlDBM8j//aAAwDAQACAAMAAAAQG8//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8QiY//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8Qq6//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUFRYXH/2gAIAQEAAT8QBGe3wzBkI2mWYWthVNfZt5lBiZDUtslmD2vhP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Nahaufnahme von Händen einer Person, die auf einer Laptop-Tastatur schreibt",
          "title": "Nahaufnahme von Händen einer Person, die auf einer Laptop-Tastatur schreibt",
          "src": "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg",
          "srcSet": ["/static/58a5de4c0dd0ba8473945cd4519d5b1d/f93b5/pexels-cottonbro-keyboard.jpg 300w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b4294/pexels-cottonbro-keyboard.jpg 600w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg 1200w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b17f8/pexels-cottonbro-keyboard.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © cottonbro / pexels.com`}</em></p>
    <h2>{`Die Bedeutung des Tastaturfokus`}</h2>
    <p>{`Ein zentrales Merkmal von barrierefreien Websites ist die Möglichkeit, sie alleine mit der Tastatur zu bedienen.
Davon profitieren etwa Nutzer:innen mit motorischen Einschränkungen, die keine Maus verwenden können.
Damit sie sinnvoll durch den Inhalt navigieren können, muss das aktuell fokussierte Element einen deutlich erkennbaren,
`}<a parentName="p" {...{
        "href": "https://www.barrierefreies-webdesign.de/richtlinien/wcag-2.1/erfolgskriterien/fokus-sichtbar.html"
      }}>{`visuellen Fokus-Indikator`}</a>{` erhalten.`}</p>
    <p>{`Browser definieren einen `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Techniques/general/G149"
      }}>{`Standard-Fokusrahmen`}</a>{`, um die
Sichtbarkeit des Tastaturfokus zu gewährleisten. Das Aussehen des Fokus-Indikators kann mithilfe der
CSS-Pseudoklasse `}<InlineCode mdxType="InlineCode">{`:focus`}</InlineCode>{` geändert werden. Zum Beispiel:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`button:focus {
    outline: 2px solid purple;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Design versus Barrierefreiheit`}</h2>
    <p>{`Nun wird der Fokus-Indikator nicht nur bei der Navigation mit der Tastatur eingeblendet, sondern auch dann,
wenn Nutzer:innen ein fokussierbares Element mit der Maus anklicken oder am Handy mit dem Finger berühren.
Für manche Nutzer:innen ist diese visuelle Änderung unerwartet und irritierend. Ihnen ist nicht bewusst,
dass der Fokus-Indikator zur Barrierefreiheit beiträgt.`}</p>
    <p>{`Oft sind Web-Entwickler:innen daher mit Kund:innen oder Grafik-Designer:innen konfrontiert, welche die Entfernung
des Fokus-Indikators aus ästhetischen Gründen fordern. Zahlreiche Websites enthalten daher CSS-Regeln wie diese:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`button:focus {
    outline: none;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Bitte tut das nicht! Ihr würdet damit Nutzer:innen, die auf die Tastaturbedienung angewiesen sind, von der
Nutzung eurer Website ausschließen.`}</p>
    <h2>{`Die Lösung heißt :focus-visible`}</h2>
    <p>{`Es gibt eine Möglichkeit, Design und Barrierefreiheit zu versöhnen: Die Pseudoklasse `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{`.
Die `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/selectors-4/#the-focus-visible-pseudo"
      }}>{`Spezifikation`}</a>{` dazu lautet:`}</p>
    <blockquote lang="en">
    The :focus-visible pseudo-class applies while an element matches the :focus pseudo-class and 
    the user agent determines via heuristics that the focus should be made evident on the element.
    </blockquote>
    <p>{`Das bedeutet: Der Fokus-Indikator wird nur eingeblendet, wenn er gebraucht wird. Also wenn ein Element fokussiert
wird und der Browser den visuellen Indikator für notwendig erachtet (z.B. bei Navigation mit der Tastatur).`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Fokus-Indikator verbergen, wenn dieser nicht benötigt wird. */
button:focus:not(:focus-visible) {
    outline: none;
}

/* Fokus-Indikator bei Navigation mit Tastatur einblenden. */
button:focus-visible {
    outline: 2px solid purple;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Auf diese Weise könnt ihr das Fokus-Verhalten etwa für Schaltflächen und Links präzise steuern.
Manche Elemente wie Eingabefelder erhalten jedoch weiterhin bei einem Mausklick den Fokus-Indikator,
da das Anklicken die Eingabe per Tastatur aktiviert und somit eine Tastaturbedienung gegeben ist.
Mit meiner `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/a11yfeatures"
      }}>{`Demo`}</a>{` könnt ihr selbst
das Verhalten testen.`}</p>
    <h2>{`Browser-Unterstützung`}</h2>
    <p>{`Das Feature wird bereits von den `}<a parentName="p" {...{
        "href": "https://caniuse.com/?search=focus-visible"
      }}>{`meisten modernen Browsern unterstützt`}</a>{`.
Auch Safari unterstützt `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` seit
der `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes"
      }}>{`Version 15.4`}</a>{`.
Wer auf Nummer sicher gehen will, kann einen regulären Fokus-Indikator definieren und diesen für Browser,
die `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` unterstützen, überschreiben.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/* Regulärer Fokus-Indikator für ältere Browser */
button:focus {
    outline: 2px solid purple;
}

/* Für Browser, die :focus-visible unterstützen */
@supports (:focus-visible) {
    button:focus:not(:focus-visible) {
        outline: none;
    }

    button:focus-visible {
        outline: 2px solid purple;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://access4all.ch/blog/2012/07/sichtbarkeit-des-tastaturfokus-auf-webseiten/"
        }}>{`Sichtbarkeit des Tastaturfokus auf Websites`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html"
        }}>{`WCAG 2.1 Erfolgskriterium 2.4.7 Fokus sichtbar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance-minimum.html"
        }}>{`WCAG 2.2 Erfolgskriterium 2.4.11: Fokus-Darstellung (Minimum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible"
        }}>{`MDN-Eintrag zu :focus-visible`}</a></li>
    </ul>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update am 14.04.2022</PostUpdateTitle>
    <p>{`Artikel wurde hinsichtlich Safari 15.4 aktualisiert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      